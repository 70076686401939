<template>
  <div>
    <div class="header">
      <div class="header_top">
        <img src="../../assets/invoice.png" alt />
        <span class="center">发票管理</span>
        <img src="../../assets/right.png" alt />
        <span class="center">发票申请列表</span>
      </div>
      <div style="display: flex; align-items: center">
        <span class="seach_text">关键字：</span>
        <el-input
          size="mini"
          placeholder="请输入发票申请企业名称"
          v-model="keywords"
          @input="getInvoiceList('seath')"
          style="margin-right: 10px; width: 300px"
          prefix-icon="el-icon-search"
          clearable
        ></el-input>
        <span class="seach_text">开票状态：</span>

        <el-select
          size="mini"
          v-model="complate"
          placeholder="请选择开票状态"
          @change="getInvoiceList('seath')"
        >
          <el-option
            :label="item.label"
            :value="item.value"
            v-for="(item, i) in invoiceStateOptions"
            :key="i"
          ></el-option>
        </el-select>
        <el-button
          size="small"
          type="primary"
          @click="addInvoice"
          v-access
          data-ctrl="invoiceInfoAdd"
          style="margin-left:15px"
          >发票相关设置</el-button
        >
        <!-- -->
        <el-button
          size="small"
          type="primary"
          icon="el-icon-plus"
          v-access
          data-ctrl="invoiceApplyAdd"
          @click="applyInvoice"
          >发票申请</el-button
        >
        <!--  -->
      </div>
    </div>

    <el-table :data="invoiceList" style="width: 100%">
      <el-table-column
        label="发票申请编号"
        prop="code"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <div @click="goDetail(scope.row.code)">
            <span class="clickColor">{{ scope.row.code }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="发票申请企业"
        prop="enterpriseName"
        :show-overflow-tooltip="true"
      ></el-table-column>

      <el-table-column label="结算平台">
        <template slot-scope="scope">{{
          scope.row.payPlatform | platformVal
        }}</template>
      </el-table-column>

      <el-table-column
        label="开票内容"
        prop="remark"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column label="开票金额/元" width="150px">
        <template slot-scope="scope">{{
          scope.row.invoiceAmount | money
        }}</template>
      </el-table-column>
      <el-table-column label="发票申请状态">
        <template slot-scope="scope">
          <span
            style="
              color: #f56c6c;
              background: #eee;
              padding: 10px 15px;
              border-radius: 50%;
            "
            v-if="!scope.row.complate"
            >未开票</span
          >
          <span
            style="
              color: #67c23a;
              background: #eee;
              padding: 10px 15px;
              border-radius: 50%;
            "
            v-else
            >已开票</span
          >
        </template>
      </el-table-column>
      <el-table-column label="发票申请时间" width="150px">
        <template slot-scope="scope">
          <span v-if="scope.row.createTime">{{
            scope.row.createTime | dateVal
          }}</span>
          <span v-else>暂无</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150">
        <template slot-scope="scope">
          <el-button
            v-access
            data-ctrl="invoiceCompleteEnter"
            type="text"
            @click="complete(scope.row)"
            :disabled="scope.row.complate"
            >完成</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination">
      <span class="clickColor">共{{ total }}条</span>
      <span class="clickColor" style="margin:0 10px">每页{{ pageSize }}条</span>
      <el-pagination
        background
        :current-page.sync="pageNumber"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-size="pageSize"
        prev-text="上一页"
        next-text="下一页"
        layout="prev,pager, next,jumper"
        :total="total"
      ></el-pagination>
    </div>

    <!-- 申请开票 -->
    <el-dialog
      :close-on-click-modal="false"
      :center="true"
      :append-to-body="true"
      :show-close="false"
      :visible.sync="invoiceVisible"
      @close="invoiceVisibleClose('invoiceFrom')"
    >
      <div slot="title" class="left_top">
        <div class="left_icon">
          <span></span>
          <span>发票申请</span>
        </div>
        <div class="right_icon">
          <span></span>
        </div>
      </div>
      <el-form :rules="invoiceRules" ref="invoiceFrom" :model="invoiceFrom">
        <el-row>
          <el-col :span="11">
            <el-form-item label="结算中心" prop="pay_platform">
              <el-select
                v-model="invoiceFrom.payPlatform"
                style="width: 100%"
                placeholder="请选择结算中心"
              >
                <el-option
                  v-for="(item, index) in platformList"
                  :key="index"
                  :label="item.shortname"
                  :value="item.pay_platform"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="11" :offset="2">
            <el-form-item label="发票种类" prop="invoiceType">
              <el-select
                v-model="invoiceFrom.invoiceType"
                style="width: 100%"
                placeholder="请选择发票种类"
              >
                <el-option
                  v-for="(item, index) in invoiceTypeList"
                  :key="index"
                  :label="item.value"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <!-- <el-form-item label="发票种类"
                          prop="invoiceType">
              <el-input v-model="invoiceFrom.invoiceType"></el-input>
            </el-form-item>-->
          </el-col>
        </el-row>

        <el-form-item label="开票内容" prop="remark">
          <el-input
            type="textarea"
            :rows="4"
            v-model="invoiceFrom.remark"
            placeholder="请输入开票内容"
          ></el-input>
        </el-form-item>
        <el-form-item style="text-align: center">
          <el-button type="primary" @click="invoiceCreate('invoiceFrom')"
            >申请开票</el-button
          >
          <el-button @click="invoiceVisibleClose('invoiceFrom')"
            >取消</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <EnterpriseInvoiceInfo
      :addVisible.sync="dialogVisible"
      :index="index"
      @addClose="dialogClose"
    ></EnterpriseInvoiceInfo>
    <!-- 快递信息 -->
    <el-dialog
      :center="true"
      @close="closeDialog('form')"
      :visible.sync="expressageVisible"
      :append-to-body="true"
      :show-close="false"
    >
      <div slot="title" class="left_top">
        <div class="left_icon">
          <span></span>
          <span>快递信息</span>
        </div>
        <div class="right_icon">
          <span></span>
        </div>
      </div>
      <el-form
        label-width="100px"
        :model="expressageFrom"
        :rules="ruleForm"
        ref="form"
        label-position="top"
      >
        <el-form-item label="快递公司" prop="expressage">
          <el-select v-model="expressageFrom.expressage" style="width: 100%">
            <el-option label="圆通" value="圆通"></el-option>
            <el-option label="中通" value="中通"></el-option>
            <el-option label="百世" value="百世"></el-option>
            <el-option label="申通" value="申通"></el-option>
            <el-option label="韵达" value="韵达"></el-option>
            <el-option label="顺丰" value="顺丰"></el-option>
            <el-option label="其他" value="其他"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="快递单号" prop="expressageOdd">
          <el-input v-model="expressageFrom.expressageOdd"></el-input>
        </el-form-item>
        <el-form-item style="text-align: center">
          <el-button type="primary" @click="expressageCreate('form')"
            >确定</el-button
          >
          <el-button @click="closeDialog('form')">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
//接口：
import { api } from '/src/api/base';
//新增接口
export const enterpriseInvoiceInfo = api('/invoice')('invoice.add.json');
// 新建发票申请
export const invoiceApplyAdd = api()('invoiceApply.add.json');
// 查询发票列表
export const invoiceApplyList = api()('invoiceApply.list.json');
// 查询发票详情
export const invoiceInfoDescribe = api()('invoiceInfo.describe.json');
// 完善快递信息
export const invoiceApplyComplete = api()('invoiceApply.complete.json');

export default {
  components: {
    EnterpriseInvoiceInfo: () => import('./enterprise-invoice-make'),
  },
  data() {
    return {
      complate: null,

      invoiceStateOptions: [
        {
          label: '全部',
          value: null,
        },
        {
          label: '已开票',
          value: true,
        },
        {
          label: '未开票',
          value: false,
        },
      ],
      invoiceTypeList: [
        {
          label: '增值税普通发票',
          value: '增值税普通发票',
        },
        {
          label: '增值税专用发票',
          value: '增值税专用发票',
        },
      ],
      keywords: '',
      invoiceVisible: false,
      invoiceFrom: {
        payPlatform: '',
        invoiceType: '',
        // invoiceAmount: "",
        remark: '',
      },
      invoiceRules: {
        payPlatform: [
          { required: true, message: '请选择开票中心', trigger: 'blur' },
        ],
        invoiceType: [
          { required: true, message: '请输入开票种类', trigger: 'blur' },
        ],
        // invoiceAmount: [
        //   { required: true, message: "请输入开票总金额", trigger: "blur" }
        // ],
        remark: [
          { required: true, message: '请输入开票内容', trigger: 'blur' },
        ],
      },
      enterpriseFrom: {},
      invoiceList: [],
      pageNumber: 1,
      pageSize: 10,
      total: 0,

      invoiceEnterpriseBtn: false,

      platformList: [],
      dialogVisible: false,
      index: 1,
      invoiceInfo: {},

      expressageFrom: {
        expressage: '',
        expressageOdd: '',
      },
      ruleForm: {
        expressage: [
          { required: true, message: '请选择快递公司名称', trigger: 'change' },
        ],
        expressageOdd: [
          { required: true, message: '请输入快递单号', trigger: 'blur' },
        ],
      },
      expressageVisible: false,
    };
  },
  created() {
    this.platformList = this.$route.params.$preload.platformList;
    this.getInvoiceInfo();
    this.getInvoiceList();
    // this.getInvoiceEnterpriseInfo();
  },
  methods: {
    handleCurrentChange(val) {
      this.pageNumber = val;
      this.getInvoiceInfo();
      this.getInvoiceList();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getInvoiceList();
      this.getInvoiceList();
    },
    // 获取发票信息
    async getInvoiceInfo() {
      this.invoiceInfo = await invoiceInfoDescribe({
        enterpriseAccount: window.localStorage.getItem('enterprise'),
      });
    },
    // 申请开票
    async applyInvoice() {
      Object.keys(this.invoiceFrom).forEach((it) => {
        this.invoiceFrom[it] = this.invoiceInfo[it];
      });
      this.invoiceVisible = true;
    },
    // 企业开票申请
    invoiceVisibleClose(form) {
      this.$refs[form].resetFields();
      this.invoiceVisible = false;
    },
    async invoiceCreate(form) {
      console.log(form);
      this.$refs[form].validate(async (valid) => {
        if (valid) {
          console.log(this.invoiceInfo);
          if (!this.invoiceInfo?.enterpriseAccount)
            return this.$message.error('需先完善发票抬头消息！');
          await invoiceApplyAdd({
            ...this.invoiceFrom,
            payPlatform: this.invoiceFrom.payPlatform + '',
            enterpriseAccount: this.invoiceInfo?.enterpriseAccount,
            enterpriseName: this.invoiceInfo?.enterpriseName,
          });
          this.invoiceVisibleClose(form);
          this.getInvoiceList();
        }
      });
    },
    // 企业开票列表
    async getInvoiceEnterpriseInfo() {
      const info = await enterpriseInvoiceInfo();
      if (info?.enterpriseName) {
        this.invoiceFrom.addresseeRecipients = info?.addresseeRecipients;
        this.invoiceFrom.addresseePhone = info?.addresseePhone;
        this.invoiceFrom.addresseeArea = info?.addresseeArea;
        this.invoiceEnterpriseBtn = true;
        this.enterpriseFrom = info;
      }
    },
    async getInvoiceList(seath) {
      if (seath) {
        this.pageNumber = 1;
      }
      const result = await invoiceApplyList({
        keywords: this.keywords,
        complate: this.complate,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        $ignoreRepeat: true,
      });
      this.invoiceList = result.list || [];
      this.total = result.total || 0;
    },
    pageChange(val) {
      this.pageNumber = val;
      this.getInvoiceList();
    },
    addInvoice() {
      this.dialogVisible = true;
      this.index++;
    },
    dialogClose() {},
    complete(row) {
      this.selectData = row;
      this.expressageVisible = true;
    },
    // 客服添加地址
    expressageCreate(form) {
      this.$refs[form].validate(async (valid) => {
        if (valid) {
          await invoiceApplyComplete({
            code: this.selectData.code,
            ...this.expressageFrom,
          });
          this.$message.success('地址添加成功');
          this.closeDialog();
        }
      });
    },
    closeDialog(form) {
      this.expressageVisible = false;
      this.$refs[form].resetFields();
      this.getInvoiceList();
    },
    goDetail(code) {
      this.$router.push({
        path: '/system/invoice/platform-invoice-info',
        query: {
          code,
        },
      });
    },
  },
};
</script>

<style scoped>
.clickColor {
  color: #409eff;
  cursor: pointer;
}
.card-header {
  display: flex;
  font-size: 14px;
  background: #f7f7f7;
  padding: 8px 20px;
  margin-bottom: 12px;
  align-items: center;
  justify-content: space-between;
}
.card-icon {
  display: flex;
  width: 4px;
  height: 15px;
  background: #659fe5;
  margin-top: 3px;
  margin-right: 10px;
}
.card-title {
  margin-right: 8px;
}
.el-col-lineheight {
  line-height: 36px;
  font-size: 14px;
  text-align: left;
}
</style>
